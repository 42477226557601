html, body {
    background: #e5e5e5;    
    font-family: sans-serif;
    max-width: 1000px;    
}

.categorias-container {
    margin: 8px 0px;
    background: #FFF;
}

.seleccion-categorias {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    
    margin: 8px 0px;
    padding: 3px;    
}

.boton-cat-container {
    border: 1px solid black;    
    display: flex;
    flex-wrap: nowrap;
    cursor: pointer;
    padding: 0px;
    margin: 2px 4px;
}

button {
    border-radius: 0px;
	font-family: sans-serif;
	font-size: 1rem;
    margin: 4px;
}

.botonCat, .botonX {
    background-color: #FFF;
    border: 0px;
    border-radius: 0px;
	font-family: sans-serif;
	font-size: 1rem;
    padding: 3px;
}

.botonCat.seleccionado, .botonX.seleccionado {
    background: #ffcc99;
    font-weight: bold;    
}

.botonX:hover {
	background: #ED553B;
}