.anio-container {
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
    max-width: 1050px;
	margin-top: 0px;	
	background-color: #FFF;
	padding: 0px;
}

.month {
	position: relative; 
	overflow: hidden;
	background: #fff;	
	width: 250px;
	padding: 20px;
	margin: 10px;
	height: 340px;
	border: 1px solid black;
}

.month h4 {
	text-align: center;
	margin: -20px -20px 30px -20px;
	padding: 10px 0;
	background: teal;
	color: #fff;
}

.selected-day {
    background-color: #d4a474;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 2%;
    color: black;
}

.day, .dow, .dummy-day {
	display: inline-block;
	width: 12.7864%;	
	float: left;
	text-align: center;
	margin-right: 1.5%;
}

.dow {
	font-weight: bold;
	margin-bottom: 10px;
}

.day {
	color: #333;
	cursor: pointer;
	box-shadow: inset 0 0 0 1px #eee;	
}
.day.weekend {
	background: #fafaff;
}

.day:hover {
	background: yellow;
}

.day, .dummy-day {
	height: 40px;
	line-height: 40px;
	margin-bottom: 1.5%;
	background: #fff;
}

.dummy-day {
	background: #f5f5f5;
	color: #ccc;
}

.day.seleccionado seleccionado{
    background: #ffcc99;
}

.seleccionado{
    background: #ffcc99;
}

.titulo {
    display: inline-block;
    margin: 10px;
}

.conTarea {
	box-shadow: inset 0 0 0 1px #ffcc99;		
}